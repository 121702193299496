import * as React from "react"
import { Link } from "gatsby"

// Shared
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from '../components/seo'

const browser = typeof window !== "undefined" && window;

// markup
const NotFoundPage = () => {
  return (
     browser && ( <div>
        <Header/>
        <Seo title={'404 Page'} description={'404: Page not found'}/>
      <div className="flex items-center justify-center h-screen bg-myGray-300">
        <div className="container">
          <div className={"row"}>
            <div className={"text-center -mt-52"}>
              <div className={"relative"}>
                <h1 className={'relative text-center text-navy-light text-9xl h-56 mb-12 font-bold tracking-tightest drop-shadow-[-12px_0_0_rgba(20,33,61)]'}>
                  <span>4</span><span>0</span><span>4</span>
                </h1>
                <p className={'text-center text-3xl mb-1'}>Oh no!</p>
                <p className={'text-center text-2xl mb-6'}>This page doesn't exist (or something terrible has happened to it).</p>
                <Link to={"/"} className={"text-white"}>
                  <button
                      className="bg-yellowTT  px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-black rounded-sm hover:shadow-lg">
                    Want to go home? </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
        <Footer/>
      </div>
     )
  )
}

export default NotFoundPage
